<template>

  <v-container>
    <h1>Mes commandes</h1>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead> Listes des commandes
          <tr>
            <th class="text-left">
              Numéro
            </th>
            <th class="text-left">
              Date
            </th>
            <th class="text-left">
              Montant
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="order in orders" :key="order.id"
          >
            <td>{{ order.number }}</td>
            <td>{{ (order.date).toString() }}</td>
            <td>{{ order.total }}</td>
            <td>
              <v-btn color="teal" outlined small rounded ><v-icon>mdi-loupe</v-icon></v-btn> |
              <v-btn color="yellow" outlined small rounded ><v-icon>mdi-swap-horizontal</v-icon></v-btn> |
              <v-btn color="red" outlined small rounded @click="deleteProduct(order.name)" ><v-icon>mdi-delete</v-icon></v-btn>

            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>

</template>

<script>
import {collection, getDocs, query} from "firebase/firestore";
import {db} from "../../main";

export default {
  name: "Order",
  data() {
    return {
      orders:[]
    }
  },
  methods: {
    async getOrders(){
      const q = query(collection(db, "commandes"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        this.orders.push(doc.data());
      });
    }
  },
  created() {
    this.getOrders();
  }
}
</script>

<style scoped>

</style>